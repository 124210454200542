import React, { useState, useEffect, useRef } from "react";

// Components
import BookmarkButton from "./BookmarkButton";
import AwardsBadge from "./AwardsBadge";

// Next
import Link from "next/link";
import Image from "next/image";

//Functions
import { fetchMetaData, processBannerImage } from "helpers/functions";
import dayjs from "dayjs";

// Styles
import styles from "styles/components/GridCard.module.scss";

// Images
import IconViews from "images/icon-views.svg";
import IconCalendar from "images/icon-calendar.svg";
import IconGlobe from "images/icon-globe.svg";
import IconFolder from "images/icon-folder.svg";

type Props = {
    data: any;
    id?: any;
    slug?: string;
    title: string;
    thumbnail: string;
    url?: any;
    date?: any;
    market?: string;
    agencies?: any;
    categories?: any;
    award_type?: string;
    award_year?: string;
    views?: any;
    hasRead?: boolean;
    objectType?: any;
    additionalClasses?: any;
};

function capitalizeFirstLetter(string: any) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

const GridCard = ({ data, slug, url, agencies, categories, award_type, hasRead, objectType, additionalClasses }: Props) => {
    let agencies_arr: any = [];
    if (agencies && agencies.length > 0) {
        agencies_arr = agencies.map((item: any) => {
            return item.name;
        })
    }

    let cat_arr: any = [];
    if (data && data.categories && data.categories.length > 0) {
        cat_arr = data.categories.map((item: any) => {
            return item.name;
        })
    }

    let formattedDate: any = data.campaign_year ? data.campaign_year : null;
    if (data.object_type && data.object_type === "master_classes") {
        let jsDate: any = data.published_at ? new Date(data.published_at.replace(/-/g, '\/')) : null;
        if (jsDate) {
            // jsDate = Math.floor(jsDate.getTime() / 1000);
            formattedDate = dayjs(jsDate.getTime()).format("MMM DD, YYYY");
        }
    }


    const [isNew, setIsNew] = useState<boolean>(false);

    const getDaysAgo = (date: any) => {
        const today = new Date();
        const date_to_reply = new Date(date);
        const timeinmilisec = date_to_reply.getTime() - today.getTime();
        return Math.ceil(timeinmilisec / (1000 * 60 * 60 * 24));
    };

    useEffect(() => {
        if (!isNew && data && data.published_at) {
            const daysAgo: any = getDaysAgo(data.published_at);
            if (daysAgo >= -56) {
                setIsNew(true);
            }
        }
    })

    const [metaData, setMetaData] = useState<any>(null);
    async function getMetaData() {
        let params: any = {}
        const key: string = data.object_type + 's';
        params[key] = [data.id];
        const pageMetaData: any = await fetchMetaData(params);
        if (!pageMetaData.error && pageMetaData.res && pageMetaData.res.data && pageMetaData.res.data.length > 0) {
            const res: any = pageMetaData.res.data[0];
            setMetaData(res);
        }
    }

    useEffect(() => {
        if (!metaData) {
            getMetaData();
        }
    }, []);

    //Award Status
    const [awardStatus, setAwardStatus] = useState<any>(null);
    const [awardLabel, setAwardLabel] = useState<string>('');

    const checkAwardStatus = () => {
        if (data.award) {
            if (data.award === 'finalist') {
                setAwardStatus('finalist');
                setAwardLabel('Finalist');
            } else if (data.award === 'dei-excellence-recognition') {
                setAwardStatus('dei-excellence-recognition');
                setAwardLabel('DEI Excellence Recognition');
            } else if (data.award === 'leveling-up-in-ce-winner') {
                setAwardStatus('leveling-up-in-ce-winner');
                setAwardLabel('Leveling Up in CE Winner');
            } else {
                setAwardStatus(data.award);
                const awardArr = data.award.split('-');
                for (let i = 0; i < awardArr.length; i++) {
                    awardArr[i] = capitalizeFirstLetter(awardArr[i]);
                }
                setAwardLabel(awardArr.join(' '));
            }
        }
    };

    useEffect(() => {
        if (data) {
            checkAwardStatus();
        }
    }, [data]);

	console.log('data', data);
	console.log('awardLabel', awardLabel);

    return (
        <div className={`${styles.briefsCard} ${metaData && !metaData.user_viewed ? styles.unread : ''} ${additionalClasses ? additionalClasses : ''}`}>
            <div className={styles.imageContainer}>
                <Link href={url}>
                    <a className={`${awardStatus ? styles.hasAward : ''} ${awardStatus ? awardStatus : ''}`}>
                        <Image
                            unoptimized={true}
                            src={data.banner_image ? processBannerImage(data.banner_image, true) : '/images/null-placeholder.png'}
                            alt="banner image alt text"
                            layout="fill"
                            objectFit="cover"
                        />
                    </a>
                </Link>
                {isNew && (
                    <span className={styles.newBadge}>
                        <span>new!</span>
                    </span>
                )}
            </div>
            <div className={styles.textContainer}>
                <div className={styles.row}>
                    <h3>
                        <Link href={url}>
                            <a>{data.title}</a>
                        </Link>
                    </h3>
                    <div className={styles.actionsContainer}>
                        <p className={styles.viewCount}>
                            <IconViews />
                            <span>{metaData ? metaData.views : (
                                <>{data.views ? data.views : 0}</>
                            )}</span>
                        </p>
                        {data.id && data.object_type && (
                            <div className={styles.bookmarkButtonContainer}>
                                <BookmarkButton
                                    post={{
                                        loaded: true,
                                        error: false,
                                        res: {
                                            data: data
                                        }
                                    }}
                                    id={data.id}
                                    slug={data ? data.slug : slug}
                                    objectType={data.object_type}
                                    additionalClasses={`${styles.bookmark} card-bookmark`}
                                />
                            </div>
                        )}
                        {(data.campaign_award || data.award) && (
                            <AwardsBadge
                                award={data.campaign_award || data.award}
                                year={data.campaign_year}
                            />
                        )}
                    </div>
                </div>
                <div className={styles.cardFooter}>
                    {(formattedDate || data.market || (categories && Object.keys(categories).length > 0)) && (
                        <div className={styles.metaContainer}>
                            {formattedDate && (
                                <p className={styles.date}>
                                    <IconCalendar />
                                    <span>{formattedDate}</span>
                                </p>
                            )}
                            {data.market && (
                                <p className={styles.markets}>
                                    <IconGlobe />
                                    <span>{data.market}</span>
                                </p>
                            )}
                            {data && data.categories && data.categories.length > 0 && Object.keys(data.categories).length > 0 && (
                                <div className={styles.categoryContainer}>
                                    <p className={styles.cat}>
                                        <IconFolder />
                                        <span>{cat_arr.join(', ')}</span>
                                    </p>
                                    <div className={`${styles.metaHover} metaHover`}>
                                        <p className={`${styles.hoverLabel} hoverLabel`}>
                                            <IconFolder />
                                            <span>Category</span>
                                        </p>
                                        <ul>
                                            {data.categories.map((item: any) => {
                                                return (
                                                    <li key={item.name}>{item.name}</li>
                                                )
                                            })}
                                        </ul>
                                    </div>
                                </div>
                            )}
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}

export default GridCard;